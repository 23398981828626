module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://blogmapadaterra.herokuapp.com","contentApiKey":"ff363d55664fbcddd9d3648202"},"production":{"apiUrl":"https://blogmapadaterra.herokuapp.com","contentApiKey":"ff363d55664fbcddd9d3648202"}},"siteConfig":{"siteUrl":"https://blog.mapadaterra.org","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Blog do mapadaterra","siteDescriptionMeta":"Ideias, reflexões, estudos experiências de vidas com materiais naturais ","shortTitle":"blog do mapa da terra","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
